









































































































import {Component, Ref, Vue} from "vue-property-decorator";
import ProductExclusion from "@/models/ProductExclusion";
import ProductExclusionService from "@/services/ProductExclusionService";
import Options from "@/models/vue/Options";
import Brand from "@/models/Brand";
import ProductCategory from "@/models/ProductCategory";
import Zone from "@/models/Zone";
import ProductCategoryService from "@/services/ProductCategoryService";
import BrandService from "@/services/BrandService";
import ZoneService from "@/services/ZoneService";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import LangModule from "@/store/LangModule";

@Component
export default class ProductExclusionView extends Vue {
	lang: any = getModule(LangModule).lang
	@Ref() readonly form!: HTMLFormElement
	@Ref() readonly editForm!: HTMLFormElement

	loading: boolean = false
	dialog: boolean = false
	editDialog: boolean = false
	productExclusions: ProductExclusion[] = []
	productExclusion: ProductExclusion = new ProductExclusion()
	brands: Brand[] = []
	productCategories: ProductCategory[] = []
	zones: Zone[] = []
	brandId: number = 0
	productCategoryId: number = 0
	zoneId: number = 0
	page: number = 1
	pageCount: number = 0
	itemsPerPage: number = 20
	totalItems: number = 0
	options: Options = new Options()
	headers = [
		{text: "Id", value: "id", align: "center" },
		{text: this.lang.zone, value: "zone.name", align: "center" },
		{text: this.lang.productCategory, value: "productCategory.name", align: "center" },
		{text: this.lang.brand, value: "brand.name", align: "center" },
		{text: this.lang.actions, value: "actions", align: "center" },
	]
	zoneRules = [(v: number) => v ? true : this.lang.fieldRequired]

	refresh() {
		ProductExclusionService.getProductExclusion(this, this.productExclusions, this.page - 1, this.itemsPerPage)
	}

	createProductExclusion() {
		if (this.form.validate()) {
			ProductExclusionService.postProductExclusion(this, this.zoneId, this.brandId, this.productCategoryId)
		}
	}

	openEditExclusionDialog(productExclusion: ProductExclusion) {
		this.editDialog = true
		this.productExclusion = productExclusion
		console.log(productExclusion)
	}

	async editProductExclusion() {
		if (this.editForm.validate()) {
			await ProductExclusionService.patchProductExclusion(this, this.productExclusion.id!, this.zoneId, this.brandId, this.productCategoryId)
			this.editDialog = false
		}
	}

	deleteProductExclusion(id: number) {
		getModule(DialogModule).showDialog(new Dialog(
				this.lang.warning,
				this.lang.exclusionDeleteQuestion,
				() => ProductExclusionService.deleteProductExclusion(this, id)
		))
	}

	openCreateDialog() {
		if (this.form) this.form.resetValidation()
		this.dialog = true
		this.zoneId = 0
		this.brandId = 0
		this.productCategoryId = 0
	}

	created() {
		this.refresh()
		ProductCategoryService.getProductCategories(this, this.productCategories)
		BrandService.getBrands(this, this.brands)
		ZoneService.getZones(this, this.zones)
	}
}
